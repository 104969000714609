@tailwind base;
@tailwind components;
@tailwind utilities;

body, #root {
  font-family: 'Poppins', sans-serif;
  background-color: #F8F8F8;
}

.table,
.table2 {
  border: 1px solid rgba(0, 0, 0, 0.01);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: whitesmoke;
}

.mol-container {
  width: 60%;
  height: 400px;
  position: relative;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px; /* Adjust the width for vertical scrollbar */
  height: 6px; /* Adjust the height for horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.uniform-icon {
  height: 16px;
  width: 16px;
}

th {
  white-space: nowrap;
}

.sidebar {
  width: 80px; /* Adjust the width for the collapsed state */
  transition: width 0.3s;
  background-color: #f8f9fa;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar.expanded {
  width: 250px; /* Adjust the width for the expanded state */
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  cursor: pointer;
}

.icons-container, .history-container, .bottom-container {
  padding: 10px;
}

.icon-link {
  display: flex;
  align-items: center;
  padding: 10px 0;
  text-decoration: none;
  color: #000;
}

.icon-link span {
  margin-left: 10px;
}

.history-container h4 {
  margin-bottom: 10px;
}

.history-link {
  display: block;
  text-decoration: none;
  color: #000;
  margin: 5px 0;
}

.toggle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;
}